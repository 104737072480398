import React, {useState, useEffect} from 'react'
import { Button } from '@mui/material';
import { get } from '../../../../utils/http';
import { INSTITUTIONS_LIST_ENDPOINT } from '../../../../utils/constants';
import LoadPictures from '../../../MonitoringPictures/LoadPictures'
import { useLoading } from '../../../../context/LoadingContext';
import LoadingComponent from '../../../LoadingComponent';

function LoadPicturesPlanilla( formId ) {
    const [openLoadPicturesModal, setOpenLoadPicturesModal] = useState(false)
    const { showLoading, hideLoading, isLoading } = useLoading();
    const [institutions, setInstitutions] = useState([]);

    useEffect(() => {
        const fetchInstitutions = async () => {
          showLoading();
          const url = INSTITUTIONS_LIST_ENDPOINT;
          const response = await get({ url });
          if (!response.error) {
            setInstitutions(response.data);
            hideLoading();
          } else if (response.error === 401) {
            hideLoading();
          }
        };
        if(openLoadPicturesModal){
            fetchInstitutions();
        }
    }, [openLoadPicturesModal]);

    const sortedInstitutions =
    institutions &&
    institutions?.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

  return (
    <>
        <Button fullWidth size="large"
            variant="outlined" 
            onClick={() => setOpenLoadPicturesModal(true)}
            sx={{backgroundColor: '#fff', borderRadius:'10px',
            fontSize: '1.3rem'}}>
            {`Agregar Registro Fotográfico`}
        </Button>
        {openLoadPicturesModal &&
        <LoadPictures 
        institutions={sortedInstitutions}
        type={"monitoringTeacher"}
        formId={formId?.formId} 
        institutionTeacher={formId?.institutionTeacher}
        setOpenModal={setOpenLoadPicturesModal} 
        />}
        {isLoading && <LoadingComponent />}
    </>
    
  )
}

export default LoadPicturesPlanilla
