import * as XLSX from 'xlsx';


/**
 * Reads the planification xlsx file
 * 
 * @param {FormData} data 
 * @returns {Promise}
 */
function ReadPlanification(data){
    return new Promise(function(resolve, reject) {
        var reader = new FileReader();
        const file = data.get("file");
        const stage = data.get("stage");
        const program = data.get("program");

        
        reader.onload = function(e) {
            var workbook = XLSX.read(e.target.result);
            let planification = new FormatPlanification(workbook, data);

            const resp = planification.Read();

            resolve({
                lessons: resp,
                error: planification._error,
                warnings: planification._warnings
            });

            /* DO SOMETHING WITH workbook HERE */
        };

        reader.onerror = function(e) {
            console.error(e);
            reject(e);
        };  
        
        reader.readAsArrayBuffer(file);

    })
}

/**
 * Serializes the planification
 * 
 * @param {Object} data -- Workbook object XLSX with the planification
 * @returns {JSON} -- Serialized planification
 */
function PlanificationSerializer(data){
    let serializer = {}

    return serializer
}

class Planification{

    constructor(workbook, data){
        this._program = data.get("program")
        this._stage = data.get("stage")
        this._version = data.get("version")
        this._year = null
        this._lessons_number = 0


        this._error = []
        this._warnings = []
        this.workbook = workbook
        this._indexSearch = 30

        // headers
        this._headersKeys = []
        this._curriculumKeys = []
        this._resourcesKeys = []
        this._lessons = []
        this._curricularAxis = []


        // curriculum
        this._curriculumIndex = 8
        
    }

    getField(sheetName, fieldNames = [], name){
        let link = ''
        let index = this._currentIndex 
 
        for (let i = index; i <= index+20; i++) {
            
            let has_slide = fieldNames.includes(this.getCell(sheetName, `B${i}`, name, false)?.trim().toLowerCase());
            
            if (has_slide) {
                let link1 = this.getCell(sheetName, `C${i}`, name, false);
                let link2 = this.getCell(sheetName, `D${i}`, name, false);

                link = this.validateUrl(link2) ? link2 : this.validateUrl(link1) ? link1 : '';
                
                if (link==='') this._warnings.push(`warning on sheet: ${sheetName} cell: B${i} field name: ${name}`);
                
                break;
            }
        }
        
        return link
    }

    validateUrl(url) {
        try {
            new URL(url);
            return true;
        } catch (_) {
            return false;
        }
    }

    getCell(sheetName, cell, name, setError = true){

        try {
            let value = this.workbook.Sheets[sheetName][cell].v;
            
            if (typeof(value) === 'string') value = value.trim();

            return value;
        } catch (error) {
            if (setError) 
                { this._error.push(`error on sheet: ${sheetName} cell: ${cell} field name: ${name}`); }

            return undefined;
        }
    }

    getHeaders(sheetName){
        if (this._headersKeys.length === 0) throw new Error('No found headers keys');
        
        let headers = {};
        this._headersKeys.forEach((header_key) => {
            let value = this.getCell(sheetName, header_key.cell, header_key.name);
            headers[header_key.name] = value
        });

        return headers
    }

    getCurriculum(sheetName, curriculumKeys){
        if (curriculumKeys.length === 0) throw new Error('No found curriculum keys');
        
        let init_index = this._curriculumIndex;
        let _curriculumIndex = this._curriculumIndex;
        let _curriculumKeys = curriculumKeys;
        
        let curriculum = {};
        let previous_row  = {};

        for (let i = _curriculumIndex; i <= _curriculumIndex; i++) {
            
            let row = {};
            let previous_interaction_key = ''
            
            _curriculumKeys.forEach((key_map) => {
                let key_cell = key_map.cell.replace(`${init_index}`,  `${i}`);
                let value = this.getCell(sheetName, key_cell, key_map.name, false);

                // It is used for when there are merged columns in the curriculum.
                if (value === undefined & previous_interaction_key !== '') value = previous_row[key_map.name];
                if (value === undefined & previous_interaction_key === '') return;

                // finish if first cell is empty 
                if (value !== undefined) {
                    previous_row[key_map.name] = value;
                    
                    // Used for nested structure in the curriculum.
                    if (previous_interaction_key === '') {
                        row[key_map.name] = {"content": value};
                    } else {
                        let aux = JSON.parse(JSON.stringify(row));
                        row = {};
                        row[key_map.name] = {"content": value, ...aux }; 
                    }

                    previous_interaction_key = key_map.name;
                }
                
            });

            if (previous_interaction_key === '') {
                this._currentIndex = i+1;
                break;
            } 

            _curriculumIndex = i+1;

            if (curriculum[previous_interaction_key] === undefined) curriculum[previous_interaction_key] = [];

            curriculum[previous_interaction_key].push(row[previous_interaction_key]);
            
        }

        return curriculum;
        
    }

    getResources(sheetName){
        let name = 'resources'
        let fieldNames = ['resource 1', 'recurso 1']
        let resources = []
        let index = this._currentIndex 
 
        for (let i = index; i <= index+this._indexSearch; i++) {
            
            let has_resource = fieldNames.includes(this.getCell(sheetName, `B${i}`, name, false)?.trim().toLowerCase());
 
            if (has_resource) {
                let link1 = this.getCell(sheetName, `C${i}`, name, false);
                let link2 = this.getCell(sheetName, `D${i}`, name, false);

                fieldNames.forEach((field, key) => {
                    const [name, num] =  field.split(' ')
                    fieldNames[key] = `${name} ${parseInt(num)+1}`
                })

                let link =  this.validateUrl(link2) ? link2 : this.validateUrl(link1) ? link1 : '';

                if (link==='') {
                    this._warnings.push(`warning on sheet: ${sheetName} cell: B${i} field name: ${name} field content: ${this.getCell(sheetName, `B${i}`, name, false)} link is empty`);
                } else {
                    resources.push({
                                "name":  `${this._program} - ${this._title} - ${this.getCell(sheetName, `B${i}`, name, false)}`,
                                "type": "RESOURCE",
                                "url": link,
                                "number": this.getCell(sheetName, `B${i}`, name, false).split(' ')[1],
                                "Program": this._program
                            })
                } 
            }
        }
 
        return resources
    }

    getIAResources(sheetName){
        let name = 'ia_resources'
        let fieldNames = ['ia 1']
        let resources = []
        let index = this._currentIndex 

        for (let i = index; i <= index+this._indexSearch; i++) {
            
            let has_resource = fieldNames.includes(this.getCell(sheetName, `B${i}`, name, false)?.trim().toLowerCase());
            
            if (has_resource) {
                let type = this.getCell(sheetName, `C${i}`, name, false);
                let description = this.getCell(sheetName, `D${i}`, name, false);
                let link1 = this.getCell(sheetName, `E${i}`, name, false);
                let link = this.validateUrl(link1) ? link1 : '';

                fieldNames.forEach((field, key) => {
                    const [name, num] =  field.split(' ')
                    fieldNames[key] = `${name} ${parseInt(num)+1}`
                })

                if (link==='' || type==='' || description==='' || type===undefined || description===undefined) {
                    this._warnings.push(`warning on sheet: ${sheetName} cell: B${i} field name: ${name}: field content: ${this.getCell(sheetName, `B${i}`, name, false)} data: cell: C${i} type: ${type} D${i} -> description: ${description} E${i} -> link: ${link}`);
                } else {
                    resources.push({
                        "type": type,
                        "description": description,
                        "url": link
                    })
                } 

            }
        }
    
        return resources
    }

    getMatetials(sheetName){

        let name = 'materials'
        let fieldNames = ['MATERIAL DE CLASE', 'CLASS MATERIAL', 'MATERIAL DE CLASE (OPCIONAL)', 'MATERIAL DE LA CLASE (OPCIONAL)', 'CLASS MATERIAL (OPTIONAL)']
        let resources = []
        let index = this._currentIndex 
    
        for (let i = index; i <= index+this._indexSearch; i++) {
            
            let has_resource = fieldNames.includes(this.getCell(sheetName, `I${i}`, name, false)?.trim().toUpperCase());
    
            if (has_resource) {
                let content = this.getCell(sheetName, `I${i+1}`, name, false);

                let optional = this.getCell(sheetName, `I${i}`, name, false)?.trim().toLowerCase().includes('opcional');

                if (content==='' || content===undefined) {
                    this._warnings.push(`warning on sheet: ${sheetName} cell: I${i} field name: ${name} field content: ${this.getCell(sheetName, `I${i}`, name, false)} data: cell: I${i+1} -> ${this.getCell(sheetName, `I${i+1}`, name, false)}`);
                } else {
                    resources.push({
                        "content": content,
                        "optional": optional,
                    })
                } 
            }
        }
    
        return resources

    }

    getScreenShots(sheetName){
        let name = 'resources'
        let fieldNames = ['screenshot 1', 'captura 1']
        let resources = []
        let index = this._currentIndex 
    
        for (let i = index; i <= index+this._indexSearch; i++) {
            
            let has_resource = fieldNames.includes(this.getCell(sheetName, `F${i}`, name, false)?.trim().toLowerCase());
    
            if (has_resource) {
                let link1 = this.getCell(sheetName, `G${i}`, name, false);
                let link = this.validateUrl(link1) ? link1 : '';

                fieldNames.forEach((field, key) => {
                    const [name, num] =  field.split(' ')
                    fieldNames[key] = `${name} ${parseInt(num)+1}`
                })

                if (link==='') {
                    this._warnings.push(`warning on sheet: ${sheetName} cell: F${i} field name: ${name} field content: ${this.getCell(sheetName, `F${i}`, name, false)} data: cell: G${i+1} -> ${this.getCell(sheetName, `G${i+1}`, name, false)}`);
                } else {
                    resources.push({
                        "name":  `${this._program} - ${this._title} - ${this.getCell(sheetName, `F${i}`, name, false)}`,
                        "type": "SCREENSHOT",
                        "url": link,
                        "number": fieldNames[0].split(' ')[1],
                        "Program": this._program
                    })
                } 
            }
        }
    
        return resources
    }

    getLessons(){
        
        this.workbook.SheetNames.forEach((sheetName) => {
            
            const headers = this.getHeaders(sheetName);

            this._title = headers['title'];
            this._term = headers['term'];
            this._lessons_number = this._lessons_number + 1;
            this._important = headers['required'];


            const curriculum = this.getCurriculum(sheetName, this._curriculumKeys);

            const curricularAxis = this._curricularAxis.length > 0 ? this.getCurriculum(sheetName, this._curricularAxis) : [];

            const slide = this.getField(
                sheetName, 
                [
                    'slide', 
                    'slides', 
                    'presentation', 
                    'presentations', 
                    'presentación', 
                    'presentacion', 
                    'presentaciónes', 
                    'presentaciones'
                ], 
                'slide'
            );

            const script = this.getField(
                sheetName, 
                [
                    'script', 
                    'scripts'
                ], 
                'script'
            );

            const resources = this.getResources(sheetName);
            const ia_resources = this.getIAResources(sheetName);
            
            const materials = this.getMatetials(sheetName);
            const screenshots = this.getScreenShots(sheetName);
            
            this._lessons.push({
                ...headers, 
                ...curriculum, 
                "slides": slide, 
                "script": script, 
                "resources": [
                    ...resources, 
                    ...screenshots
                ], 
                "ia_resources": ia_resources, 
                "materials": materials,
                "stage": this._stage,
                ...curricularAxis,
                "program": {
                    "program": this._program,
                    "term": this._term,
                    "version": this._version,
                    "lesson_number": this._lessons_number,
                    "important": this._important
                }
            });
        });
    }

}


class FormatPlanification extends Planification {

    constructor(workbook, data){
        super(workbook, data);

        this.selectStage = {
            "Preescolar": "FormatPreschool", 
            "Primaria Mayor": "FormatPrimary",
            "Primaria Menor": "FormatPrimary",
            "Bachillerato": "FormatSecondary"   
        }
        
    }


    Read(){
       return this[this.selectStage[this._stage]]();
    }

    FormatPreschool(){

        //  falta agregar los campos 
        /* 
        "stage": "Preescolar",
        "curricular_axis": [
                {
                    "content": "Lúdico"
                }
            ],
        "program": {
            "program": 185,
            "term": 2,
            "version": "1",
            "lesson_number": 1,
            "important": true
        }
        */

        // Pueden causar probleamas - revisar serializer.
        /* 
        "year": 4,
        "term": 7,
        */ 

        this._headersKeys = [
            {"cell": "J3", "name": "title"},
            {"cell": "J4", "name": "description"},
            {"cell": "J5", "name": "lesson_id"},
            {"cell": "L3", "name": "term"},
            {"cell": "L4", "name": "year"},
            {"cell": "L5", "name": "required"},
            {"cell": "G8", "name": "achievement_indicators"},
            {"cell": "H8", "name": "lesson_goal"},
            {"cell": "I8", "name": "beginning"},
            {"cell": "J8", "name": "development"},
            {"cell": "K8", "name": "closure"},
            {"cell": "L8", "name": "complementary_activities"},
        ]

        this._curricularAxis = [
            {"cell": "B8", "name": "curricular_axis"}  
        ]

        this._curriculumIndex = 8

        this._curriculumKeys = [
            {"cell": "C8", "name": "learning_subject"},
            {"cell": "D8", "name": "component"},
            {"cell": "E8", "name": "goal"},
            {"cell": "F8", "name": "learning_outcomes"}
        ]

        this.getLessons();

        return this._lessons;
    }

    FormatSecondary(){

        this._headersKeys = [
            {"cell": "H3", "name": "title"},
            {"cell": "H4", "name": "description"},
            {"cell": "H5", "name": "lesson_id"},
            {"cell": "J3", "name": "term"},
            {"cell": "J4", "name": "year"},
            {"cell": "J5", "name": "required"},
            {"cell": "F8", "name": "lesson_goal"},
            {"cell": "G8", "name": "beginning"},
            {"cell": "H8", "name": "development"},
            {"cell": "I8", "name": "closure"},
            {"cell": "J8", "name": "main_activities"},
        ]
    
        this._curriculumIndex = 8
    
        this._curriculumKeys = [
            {"cell": "B8", "name": "learning_subject"},
            {"cell": "C8", "name": "topic"},
            {"cell": "D8", "name": "generating_topic"},
            {"cell": "E8", "name": "curriculum_references"}
        ]
        
        this.getLessons();

        return this._lessons;
    }

    FormatPrimary(){

        this._headersKeys = [
            {"cell": "I3", "name": "title"},
            {"cell": "I4", "name": "description"},
            {"cell": "I5", "name": "lesson_id"},
            {"cell": "K3", "name": "term"},
            {"cell": "K4", "name": "year"},
            {"cell": "K5", "name": "required"},
            {"cell": "G8", "name": "achievement_indicators"},
            {"cell": "F8", "name": "lesson_goal"},
            {"cell": "H8", "name": "beginning"},
            {"cell": "I8", "name": "development"},
            {"cell": "J8", "name": "closure"},
            {"cell": "K8", "name": "main_activities"},
        ]

        this._curricularAxis = [
            {"cell": "B8", "name": "curricular_axis"}  
        ]

        this._curriculumIndex = 8

        this._curriculumKeys = [
            {"cell": "C8", "name": "learning_subject"},
            {"cell": "D8", "name": "component"},
            {"cell": "E8", "name": "curriculum_content"}
        ]

        this.getLessons();

        return this._lessons;
    }
}





export default ReadPlanification