import React from 'react';
import { Grid, TextField, styled, Paper, MenuItem, Autocomplete } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';

const typeChoices = [
    { value: 'Request', label: 'Solicitud' },
    { value: 'Incident', label: 'Incidencia' },
    { value: 'Event', label: 'Evento' },
    { value: 'Information', label: 'Información' },
  ];

  const typeOfChoices = (type) => {
    switch (type) {
      case 'Request':
        return 'Solicitud'
      case 'Solicitud':
        return 'Solicitud'
      case 'Incident':
        return 'Incidencia'
      case 'Incidencia':
        return 'Incidencia'
      case 'Event':
        return 'Evento'
      case 'Evento':
        return 'Evento'
      case 'Information':
        return 'Información'
    }
  };

  const priorityChoices = [
    { value: 1, label: 'Alta' },
    { value: 2, label: 'Media' },
    { value: 3, label: 'Baja' },
  ];
  
  const statusChoices = [
    { value: 'Pendiente', label: 'Pendiente' },
    { value: 'En Proceso', label: 'En Proceso' },
    { value: 'Resuelto', label: 'Resuelto' },
    { value: 'Cerrado', label: 'Cerrado' },
  ];
  
  const incidentTypeChoices = [
    { value: 'Lost Equipment', label: 'Perdida de equipo' },
    { value: 'Damaged Equipment', label: 'Equipo Dañado' },
    { value: 'Unique equipment presents failures', label: 'Equipo único presenta fallas' },
    { value: 'Equipment presents failures', label: 'Equipo presenta fallas' },
    { value: 'Incomplete curricular equipment', label: 'Equipo curricular incompleto' },
    { value: 'Missing curricular equipment', label: 'Equipo curricular faltante' },
    { value: 'Incomplete exploratory equipment', label: 'Equipo exploratorio incompleto' },
    { value: 'Missing exploratory equipment', label: 'Equipo exploratorio faltante' },
    { value: 'Missing material carpet, tape, cables', label: 'Material faltante (alfombra, teipe, cables)' },
    { value: 'Uncommunicated activity in school', label: 'Actividad en colegio no comunicada' },
    { value: 'Uncommunicated optional activity', label: 'Actividad opcional no comunicada' },
    { value: 'Uncommunicated internal institution activity', label: 'Actividad interna de la institución no comunicada' },
    { value: 'Uncommunicated change in class schedule', label: 'Cambio en esquema de clases no comunicado' },
    { value: 'Uncommunicated change in laboratory distribution', label: 'Cambio en distribución de laboratorios no comunicado' },
    { value: 'Activity disrupts visit in the interior', label: 'Actividad interrumpe visita en el interior' },
    { value: 'Response times longer than expected administration', label: 'Tiempos de respuesta sobre lo esperado (dirección)' },
    { value: 'Response times longer than expected coordination', label: 'Tiempos de respuesta sobre lo esperado (coordinación)' },
    { value: 'Response times longer than expected teacher', label: 'Tiempos de respuesta sobre lo esperado (docente)' },
    { value: 'Material on platform in incorrect language', label: 'Material en plataforma en idioma incorrecto' },
    { value: 'Program material that does not correspond', label: 'Material de programa que no corresponde' },
    { value: 'Activities or support material do not match the class', label: 'Actividades o material de apoyo no coincide con la clase' },
    { value: 'Material with spelling grammatical errors', label: 'Material con errores ortográficos / redacción' },
    { value: 'Platform out of service', label: 'Plataforma fuera de funcionamiento' },
    { value: 'Error in teacher modules', label: 'Error en los módulos docentes' },
    { value: 'Error in internal use modules', label: 'Error en los módulos de uso interno' },
    { value: 'Error without warning', label: 'Error sin advertencia' },
    { value: 'Reports having no assigned schedules', label: 'Reporta que no tiene horarios asignados' },
    { value: 'Other', label: 'Otro' },
  ];
  
  const typeEventChoices = [
    { value: 'Meeting', label: 'Reunión' },
    { value: 'Chat', label: 'Chat' },
    { value: 'Call', label: 'Llamada' },
    { value: 'A demonstration class was conducted', label: 'Se llevó a cabo una clase demostrativa' },
    { value: 'Feedback session', label: 'Sesión de feedback' },
    { value: 'Meeting with the institution coordination', label: 'Reunión con coordinación de la institución' },
    { value: 'Other', label: 'Otro' },
  ];

  const typeofRequest = [
    { value: 'Equipment', label: 'Equipo' },
    { value: 'Material', label: 'Material' },
    { value: 'Activity', label: 'Actividad' },
    { value: 'Schedule', label: 'Horario' },
    { value: 'Meeting', label: 'Reunión' },
    { value: 'Other', label: 'Otro' },
  ]
  
  const typeofRequestChoices = (type) => {
    switch (type) {
      case 'Equipment':
        return 'Equipo';
      case 'Material':
        return 'Material';
      case 'Activity':
        return 'Actividad';
      case 'Schedule':
        return 'Horario';
      case 'Meeting':
        return 'Reunión';
      case 'Other':
        return 'Otro';
      default:
        return 'No definido';
    }
  }
  const typeofEventChoices = (type) => {
    switch (type) {
      case 'Meeting':
        return 'Reunión';
      case 'Chat':
        return 'Chat';
      case 'Call':
        return 'Llamada';
      case 'A demonstration class was conducted':
        return 'Se llevó a cabo una clase demostrativa';
      case 'Feedback session':
        return 'Sesión de feedback';
      case 'Meeting with the institution coordination':
        return 'Reunión con coordinación de la institución';
      case 'Other':
        return 'Otro';
      default:
        return 'No definido';
    }
  };
  const typeInformationChoices = [
    { value: 'The teacher presented a proposal to adapt a class', label: 'El docente presentó una propuesta de adaptar una clase' },
    { value: 'The teacher attended all the K Labs of the month', label: 'El docente asistió a todos los K Lab del mes' },
    { value: 'The teacher won the innovative teacher award', label: 'El docente ganó el docente innovador' },
    { value: 'The teacher was part of the honor roll', label: 'El docente formó parte del cuadro de honor' },
    { value: 'Participates in the continuous training process', label: 'Participa del proceso de formación continua' },
    { value: 'The teacher reports having received the equipment', label: 'Docente reporta haber recibido los equipos' },
    { value: 'Reports working in half-groups', label: 'Reporta que trabajan en mitad de grupo' },
    { value: 'Reports working in full groups', label: 'Reporta que trabajan en grupo completo' },
    { value: 'Reports the frequency of classes: weekly biweekly', label: 'Reporta la periodicidad de las clases: semanal - quincenal' },
    { value: 'Reports working at another institution shared schedule', label: 'Reporta trabajo en otra institución - horario compartido' },
    { value: 'Other', label: 'Otro' },
  ]
  const typeofInformationChoices = (type) => {
    switch (type) {
      case 'The teacher presented a proposal to adapt a class':
        return 'El docente presentó una propuesta de adaptar una clase';
      case 'The teacher attended all the K Labs of the month':
        return 'El docente asistió a todos los K Lab del mes';
      case 'The teacher won the innovative teacher award':
        return 'El docente ganó el docente innovador';
      case 'The teacher was part of the honor roll':
        return 'El docente formó parte del cuadro de honor';
      case 'Participates in the continuous training process':
        return 'Participa del proceso de formación continua';
      case 'The teacher reports having received the equipment':
        return 'Docente reporta haber recibido los equipos';
      case 'Reports working in half-groups':
        return 'Reporta que trabajan en mitad de grupo';
      case 'Reports working in full groups':
        return 'Reporta que trabajan en grupo completo';
      case 'Reports the frequency of classes: weekly biweekly':
        return 'Reporta la periodicidad de las clases: semanal - quincenal';
      case 'Reports working at another institution shared schedule':
        return 'Reporta trabajo en otra institución - horario compartido';
      case 'Other':
        return 'Otro';
      default:
        return 'No definido';
    }
  };
  const areaChoices = [
    { value: 'Administration', label: 'Administración' },
    { value: 'IT', label: 'IT' },
    { value: 'Logistics and Inventory', label: 'Logística e Inventario' },
    { value: 'Human Resources', label: 'Recursos Humanos' },
    { value: 'Post Sales', label: 'Post Venta' },
    { value: 'Sales', label: 'Ventas' },
    { value: 'Monitoring', label: 'Seguimiento' },
    { value: 'Other', label: 'Otro' },
  ];

  const eventMapOptions = {
    'Incident': incidentTypeChoices,
    'Request': typeofRequest,
    'Information': typeInformationChoices,
    'Event': typeEventChoices,
  }
  
  const stageChoices = [
    { value: 'Preescolar', label: 'Preescolar' },
    { value: 'Primaria Menor', label: 'Primaria Menor' },
    { value: 'Primaria Mayor', label: 'Primaria Mayor' },
    { value: 'Bachillerato', label: 'Bachillerato' },
  ];

  const priorityChoicesColor = (priority) => {
    switch (priority) {
      case 1:
        return { value: 1, label: 'Alta', className: 'fa fa-exclamation-triangle', style: {
          backgroundColor: 'var(--sec-red)',
          color: '#fff',
          padding: '7px',
          borderRadius: '7px',
          fontSize: '16px',
        },};
      case 2:
        return { value: 2, label: 'Media', className: 'fa fa-exclamation-triangle', style: {
          backgroundColor: 'var(--sec-orange)', 
          color: '#fff',
          padding: '7px',
          borderRadius: '7px',
          fontSize: '16px',
        }, };
      case 3:
        return { value: 3, label: 'Baja', className: 'fa fa-exclamation-triangle', style: {
          backgroundColor: 'var(--sec-blue)', 
          color: '#fff',
          padding: '7px',
          borderRadius: '7px',
          fontSize: '16px',
        }, };
      default:
        return { value: 2, label: 'Media', className: 'fa fa-exclamation-triangle', style: {
          backgroundColor: 'var(--sec-orange)', 
          color: '#fff',
          padding: '7px',
          borderRadius: '7px',
          fontSize: '16px',
        }, };
    }
  }
    
  const getStatusIcon = (status) => {
    switch (status) {
      case 'Pendiente':
        return {
          className: 'fa fa-clock',
          style: {
            backgroundColor: 'var(--sec-orange)', 
            color: '#fff',
            padding: '7px',
            borderRadius: '7px',
            fontSize: '16px',
          },
        };
      case 'En Proceso':
        return {
          className: 'fas fa-circle-notch',
          style: {
            backgroundColor: 'var(--sec-blue)',
            color: '#fff',
            padding: '7px',
            borderRadius: '7px',
            fontSize: '16px',
          },
        };
      case 'Resuelto':
        return {
          className: 'fas fa-check-circle',
          style: {
            backgroundColor: 'var(--sec-green)',
            color: '#fff',
            padding: '7px',
            borderRadius: '7px',
            fontSize: '16px',
          },
        };
      case 'Cerrado':
        return {
          className: 'fas fa-times-circle',
          style: {
            backgroundColor: 'var(--sec-red)', // Puedes definir un color para closed
            color: '#fff',
            padding: '7px',
            borderRadius: '7px',
            fontSize: '16px',
          },
        };
      default:
        return {
            className: 'fa fa-clock',
            style: {
              backgroundColor: 'var(--sec-orange)', 
              color: '#fff',
              padding: '7px',
              borderRadius: '7px',
              fontSize: '16px',
            },
        };
    }
  };

  const getStatusIconList = (status) => {
    let style = {};
  
    switch (status) {
      case 'Pendiente':
        style = {
          backgroundColor: 'var(--sec-orange)', 
          color: '#fff',
          padding: '5px 10px',
          borderRadius: '20px',
          fontSize: '14px',
        };
        break;
      case 'En Proceso':
        style = {
          backgroundColor: 'var(--sec-blue)',
          color: '#fff',
          padding: '5px 10px',
          borderRadius: '20px',
          fontSize: '14px',
        };
        break;
      case 'Resuelto':
        style = {
          backgroundColor: 'var(--sec-green)',
          color: '#fff',
          padding: '5px 10px',
          borderRadius: '20px',
          fontSize: '14px',         
        };
        break;
      case 'Cerrado':
        style = {
          backgroundColor: 'var(--sec-red)', 
          color: '#fff',
          padding: '5px 10px',
          borderRadius: '20px',
          fontSize: '14px',
        };
        break;
      default:
        style = {
          backgroundColor: 'var(--sec-orange)', 
          color: '#fff',
          padding: '5px 10px',
          borderRadius: '20px',
          fontSize: '14px',
        };
        break;
    }  
    return <div style={style}>{status}</div>;
  };
  
  const areaResponsible = (area_responsable) => {

    let area = "";
  
    switch (area_responsable) {
      case "Administration":
        area = "Administración";
        break;
      case "Logistics and Inventory":
        area = "Logística y Inventario";
        break;
      case "Human Resources":
        area = "Recursos Humanos";
        break;
      case "IT":
        area = "IT";
        break;
      case "Post Sales":
        area = "Post Venta";
        break;
      case "Sales":
        area = "Ventas";
        break;
      case "Monitoring":
        area = "Seguimiento";
        break;
      case "Other":
        area = "Otro";
        break;
    }
  
    return area;
  };

  const typeofEvents = (type) => {
    switch (type) {
      case 'Lost Equipment':
        return 'Equipo Perdido';
      case 'Damaged Equipment':
        return 'Equipo Dañado';
      case 'Unique equipment presents failures':
        return 'Equipo único presenta fallas';
      case 'Equipment presents failures':
        return 'Equipo presenta fallas';
      case 'Incomplete curricular equipment':
        return 'Equipo curricular incompleto';
      case 'Missing curricular equipment':
        return 'Equipo curricular faltante';
      case 'Incomplete exploratory equipment':
        return 'Equipo exploratorio incompleto';
      case 'Missing exploratory equipment':
        return 'Equipo exploratorio faltante';
      case 'Missing material carpet, tape, cables':
        return 'Material faltante (alfombra, teipe, cables)';
      case 'Uncommunicated activity in school':
        return 'Actividad en colegio no comunicada';
      case 'Uncommunicated optional activity':
        return 'Actividad opcional no comunicada';
      case 'Uncommunicated internal institution activity':
        return 'Actividad interna de la institución no comunicada';
      case 'Uncommunicated change in class schedule':
        return 'Cambio en esquema de clases no comunicado';
      case 'Uncommunicated change in laboratory distribution':
        return 'Cambio en distribución de laboratorios no comunicado';
      case 'Activity disrupts visit in the interior':
        return 'Actividad interrumpe visita en el interior';
      case 'Response times longer than expected administration':
        return 'Tiempos de respuesta sobre lo esperado (dirección)';
      case 'Response times longer than expected coordination':
        return 'Tiempos de respuesta sobre lo esperado (coordinación)';
      case 'Response times longer than expected teacher':
        return 'Tiempos de respuesta sobre lo esperado (docente)';
      case 'Material on platform in incorrect language':
        return 'Material en plataforma en idioma incorrecto';
      case 'Program material that does not correspond':
        return 'Material de programa que no corresponde';
      case 'Activities or support material do not match the class':
        return 'Actividades o material de apoyo no coincide con la clase';
      case 'Material with spelling grammatical errors':
        return 'Material con errores ortográficos / redacción';
      case 'Platform out of service':
        return 'Plataforma fuera de funcionamiento';
      case 'Error in teacher modules':
        return 'Error en los módulos docentes';
      case 'Error in internal use modules':
        return 'Error en los módulos de uso interno';
      case 'Error without warning':
        return 'Error sin advertencia';
      case 'Reports having no assigned schedules':
        return 'Reporta que no tiene horarios asignados';
      case 'Other':
        return 'Otro';
      default:
        return 'No definido';
    }
  };
  
  const priority = (priority) => {
    switch (priority) {
      case 1:
        return 'Alta';
      case 2:
        return 'Media';
      case 3:
        return 'Baja';
    }
  };
  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#000",
        fontSize: "13px",
        fontWeight: "bold",
        borderBottom: "1px solid #E0E0E0",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#f5f5f5",
        borderBottomColor: "#FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };
  const eventTypeMapOptions = {
    'Incident': typeofEvents,
    'Request': typeofRequestChoices,
    'Information': typeofInformationChoices,
    'Event': typeofEventChoices,
  }

  const getTooltipMessage = (eventType) => {
    switch (eventType) {
      case 'Incidencia':
        return 'Selecciona a la persona que se ha visto afectada por la incidencia.';
      case 'Solicitud':
        return 'Selecciona a la persona que solicitó este servicio o acción.';
      case 'Evento':
        return 'Selecciona a la persona responsable de participar o gestionar este evento.';
      default:
        return 'Selecciona a la persona relacionada con este registro.';
    }
  };
  
  const isExternal = (type) => {
    return type?.role === "external_teacher" || type?.role === "external_coordinator" ? true : false

  }
  const handleRowClick = (row, navigate) => {
    const routeMap = {
      Request: `/seguimiento/events/request/${row.id}`,
      Event: `/seguimiento/events/event/${row.id}`,
      Incident: `/seguimiento/events/incidence/${row.id}`,
      Information: `/seguimiento/events/information/${row.id}`,
    };
  
    const path = routeMap[row.type_event];
    if (path) navigate(path, { relative: false });
  };
  const Container = styled(Paper)({
    width: '100%',
    margin: '0px 20px 0px 0px',
    backgroundColor: 'var(--background-gray)',
    padding: '20px',
    borderRadius: '10px',
  });
  
  const FormWrapper = styled('div')({
    backgroundColor: 'var(--main-white)',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  });
  
  const Title2 = styled('p')({
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginBottom: '5px',
  });
  
  const Text2 = styled('p')({
    fontSize: '1.3rem',
    paddingTop: '5px',
    color: 'var(--main-blue)',
    fontWeight: 'bold',
  });
  
  const CustomTextField = styled(TextField)({
    '& .MuiInputBase-root': {
      borderRadius: '15px',
      fontSize: '1.2rem',
      width: '100%',
    },
    '& .MuiInputLabel-root': {
      fontSize: '1.2rem',
    },
    '& .MuiMenuItem-root': {
      fontSize: '1.2rem',
    },
    fontSize: '1.2rem',
  });
  
  const AutocompleteStyled = styled(Autocomplete)({
    '& .MuiInputBase-root': {
      borderRadius: '15px',
      fontSize: '1.2rem',
      width: '100%',
    },
   '& .MuiInputBase-input': {
      width: '100%', 
    },
    '& .MuiAutocomplete-inputRoot': {
      fontSize: '1.2rem',
      minWidth: '100%',
    },
    '& .MuiInputBase-input': {
      width: '100%',
    },
    fontSize: '1.2rem',
  });
  
  const MenuItemStyled = styled(MenuItem)({
    fontSize: '1.2rem',
    '&:hover': {
      backgroundColor: 'var(--main-gray)',
    }
  });
  
  const IconHelpStyled = styled(HelpIcon)({
    fontSize: '2rem', 
    color: 'var(--main-blue)',
    cursor: 'pointer', 
    '&:hover': {
      color: 'var(--sec-blue)',
    },
  });
  const TooltipStyled = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .MuiTooltip-tooltip`]: {
      fontSize: '1.2rem',  
      backgroundColor: 'var(--main-gray)',  
      color: '#fff',  
      padding: '10px',  
      borderRadius: '10px',  
    },
    [`& .MuiTooltip-arrow`]: {
      color: 'var(--main-gray)', 
    },
  });
  
  export {typeChoices, statusChoices,
    incidentTypeChoices,
    typeEventChoices,
    typeOfChoices,
    areaChoices,
    stageChoices,
    priorityChoices,
    typeofRequest,
    typeofEventChoices,
    typeofRequestChoices,
    priority,
    getStatusIcon,
    priorityChoicesColor,
    areaResponsible,
    typeofEvents,
    typeInformationChoices,
    typeofInformationChoices,
    handleRowClick,
    customStyles,
    getStatusIconList,
    getTooltipMessage,
    isExternal,
    eventMapOptions,
    eventTypeMapOptions,
    Container,
    FormWrapper,
    Title2,
    Text2,
    CustomTextField,
    AutocompleteStyled,
    MenuItemStyled,
    IconHelpStyled,
    TooltipStyled
  }