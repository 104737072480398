import React from "react";
import styles from "./Reminder.module.css";
import { wordCapitalize } from "../../utils/capitalize";

const Reminder = ({ form, time = null, type, capacitacion, i, status, title, handleClick, capacitacionName }) => {
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };
  return (
    <>
    {type === "surveys" &&
    <div className={styles.card_reminder} key={form.id}>
      {form?.answered_at === null ? (
        <i
          className="far fa-circle"
          style={{ color: "#D30019", fontSize: "14px" }}
        ></i>
      ) : (
        <i
          className="fas fa-circle"
          style={{ color: "#58AB27", fontSize: "14px" }}
        ></i>
      )}
      <div className={styles.left}>
        <p className={styles.title}>{ form?.template?.name ? form?.template?.name : `Encuesta de seguimiento` }</p>
        {form.institution && (
          <p className={styles.time}>{`${form.institution.name}`}</p>
        )}
        {form.completed === true ? (
          <p className={styles.time}>{`Completado`}</p>
        ) : time !== null ? (
          <p className={styles.time}>{`${time} restantes`}</p>
        ) : ''}
      </div>
    </div>}
    {type === "capacitacion" &&
    <div className={styles.card_reminder} key={capacitacion?.id}>
        <i
          className="far fa-circle"
          style={{ color: "#D30019", fontSize: "14px" }}
        ></i>
      <div className={styles.left} style={{marginLeft: "5px"}}>
        <p className={styles.title} style={{fontWeight: 'bold'}}>
          {wordCapitalize(capacitacion?.title)}
        </p>
        {time !== null ? (
          <p className={styles.time}>{`${time} restantes`}</p>
        ) : ''}
      </div>
    </div>}
    {type === "capacitacionAnual" &&
    <div className={styles.card_reminder} key={capacitacion?.id}>
        {status === null ? (
        <i
          className="far fa-circle"
          style={{ color: "#D30019", fontSize: "14px" }}
        ></i>
      ) : (
        <i
          className="fas fa-circle"
          style={{ color: "#fed200", fontSize: "14px" }}
        ></i>
      )}
      <div className={styles.left}>
        <p className={styles.title} style={{fontWeight: 'bold'}}>
          {status === null ? `Confirma tu asistencia: Sesión ${i} de ${capacitacionName}` : 
          `Próxima sesión:  Sesión ${i} de ${capacitacionName}`}
        </p>
        <p className={styles.time}>{`${new Date(capacitacion.date)?.toLocaleString("es-ES", options)}`}</p>
      </div>
    </div>} 
    {type === "instructor" &&
    <div className={styles.card_reminder} key={capacitacion?.id}>
        <i
          className="fas fa-circle"
          style={{ color: "#fed200", fontSize: "14px" }}
        ></i>
      <div className={styles.left}>
        <p className={styles.title} style={{fontWeight: 'bold'}}>
          {`Próxima Capacitación a dar: Sesión ${i} - ${capacitacionName}`}
        </p>
        <p className={styles.time}>{`${new Date(capacitacion.date)?.toLocaleString("es-ES", options)}`}</p>
      </div>
    </div>} 
    {type === "teacher" &&
      <div className={styles.card_reminder} onClick={handleClick}>
      <i
        className="fas fa-circle"
        style={{ color: "#685bc7", fontSize: "14px" }}
      ></i>

      <div className={styles.left}>
        <p className={styles.title}>{title}</p>
      </div>
      </div>} 
    </>
  );
};

export default Reminder;
