import React, { useState, useContext, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import TaskRoundedIcon from '@mui/icons-material/TaskRounded';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { MySelect, MySubmitButton } from "../FormFields";
import Modal from "../Modal";
import { CurrentUserContext } from "../../context/CurrentUserProvider";
import styles from "./LoadPictures.module.css";
import { post } from "../../utils/http";
import { BASE_URL } from "../../utils/constants";
import { AlertsContext } from "../../context/AlertsContext";

function LoadPictures({ setOpenModal, type, institutions, formId, institution, institutionTeacher }) {
  const { currentUser } = useContext(CurrentUserContext);
  const fileInputRef = useRef(null);
  const showSnackbar = useContext(AlertsContext);
  const [files, setFiles] = useState([]);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))
  
  function bytesToMegabytes(bytes) {
    const bytesPerMB = 1024 * 1024;
    return ((bytes / bytesPerMB) + 0.21);
  }

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const handleFiles = (newFiles) => {
    const updatedFiles = [...files, ...newFiles]?.filter((file, index, self) =>
      index === self.findIndex(f => f.name === file.name) // Elimina duplicados
    );

    const allFilesAreValid = updatedFiles.every(file => bytesToMegabytes(file?.size) <= 10);

    setFiles(updatedFiles);
    setIsSubmitEnabled(allFilesAreValid);
  };

  const removeFile = (index) => {
    const updatedFiles = files?.filter((_, fileIndex) => fileIndex !== index);

    const allFilesAreValid = updatedFiles?.every(file => bytesToMegabytes(file?.size) <= 10);

    setFiles(updatedFiles);
    setIsSubmitEnabled(allFilesAreValid);
  };

  const sendPictures = async (data) => {
    const url = `${BASE_URL}/monitoring_pictures/`;
    const response = await post({ url, data });
    if (!response.error) {
      showSnackbar("Registro fotográfico enviado", "success");
    } else {
      showSnackbar("Error al cargar el registro", "error");
    }
    setOpenModal(false);
  };
  const newInst = institutions?.find((insti) =>  insti.name === institutionTeacher);
  return (
    <Modal title={"Registro Fotográfico"} width={isDesktop ? '60%' : '90%'}
    modalOpen={setOpenModal}>
       <div
        style={{
          width: "100%",
          maxHeight: "80vh",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
        }}
      >
      <Formik
        initialValues={{
          institution: newInst ? newInst.id : null,
        }}
        validationSchema={Yup.object()}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const formData = new FormData();
          // Esto se va a subir a produccion, pero un teacher tiene mas de una institucion
          // esto se debe corregir porque mas adelante dara problemas al subir las imagenes
          let inst = values.institution
            ? values.institution
            : currentUser.teacher_profile.institution[0].id
          formData.append("teacher", currentUser.id);
          formData.append("institution", inst);
          formData.append("files_count", files?.length);
          files?.forEach((file, index) => {
            formData.append(`file${index}`, file);
          });
          if(type === "monitoringTeacher" && formId){
            formData.append("monitoring_form", formId);
          }
          // Dejo este codigo comentado para cuando se hagan las otras correcciones
          // for (let [key, value] of formData.entries()) {
          //   console.log(`${key}:`, value);
          // }
          console.log("formData", formData);
          sendPictures(formData);

          setTimeout(() => {
            setSubmitting(false);
          }, 400);
          resetForm();
        }}
      >
        <Form className={styles["form"]}>
          <div className={styles["form__fields"]} onDragOver={onDragOver}
              onDrop={(e) => {
                e.preventDefault();
                handleFiles(Array.from(e.dataTransfer.files));
              }}>
            <p style={{textAlign: 'center', marginBottom: '15px'}}>
              Las imágenes no deben exceder los 10MB</p>
              <Box sx={{width: '100%', padding: '0 10px'}}>
                <form
                  action="#"
                  onClick={() => fileInputRef.current.click()}
                  encType="multipart/form-data"
                >
                  <Box sx={{display: 'flex', alignItems:'center', 
                justifyContent:'center', flexDirection: 'column',
                borderRadius: '10px', border: '2px dashed var(--main-blue)', 
                cursor:'pointer', width:'100%', padding: '20px 10px'}}>
                 <input
                type="file"
                multiple
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={(e) => handleFiles(Array.from(e.target.files))}
                />
                  <i className="fas fa-cloud-upload-alt"></i>
                  <p style={{textAlign: 'center'}}>{`Arrastra tus imágenes aquí o has click para seleccionarlas`}</p>
                  </Box>
                </form>
                {type === "monitoringTeacher" && (
                    <div style={{width:'100%', marginBottom: '20px'}}>
                      <MySelect label="Institución" width={"100%"}
                      name="institution">
                        {institutions?.map((i) => (
                          <MenuItem value={i.id} sx={{fontSize: '14px'}}>{i.name}</MenuItem>
                        ))}
                      </MySelect>
                    </div>
                  )}
              {files?.length > 0 && (
                <Box sx={{margin: '15px 0 ', display: 'flex', 
                flexDirection: 'column', alignItems:'center', width:'100%'}}>
                  {files?.map((file, index) => {
                    return(
                      <>
                      {bytesToMegabytes(file?.size) <= 10 ?
                      <Grid container direction={"row"}>
                        <Grid item md={2} xs={2} sx={{display:'flex', justifyContent:'center'}}>
                        <TaskRoundedIcon sx={{color:"#43b02a"}}
                        fontSize="large" />
                        </Grid>
                        <Grid item md={8} xs={8}>
                        <span style={{
                          whiteSpace: 'nowrap', 
                          overflow: 'hidden', 
                          textOverflow: 'ellipsis', 
                          display: 'inline-block',
                          maxWidth: '100%',
                        }}>
                          {`${file?.name} - ${bytesToMegabytes(file?.size)?.toFixed(2)} MB`}
                        </span> 
                        </Grid>
                        <Grid item md={2} xs={2} sx={{display:'flex', justifyContent:'center'}}>
                         <IconButton size="small" onClick={() => removeFile(index)}>
                            <DeleteRoundedIcon sx={{color:"#d30019"}}
                             fontSize="large" />
                          </IconButton>
                        </Grid>
                      </Grid> : 
                        <li className={styles.row}>
                        <div className={styles.content}>
                          <DisabledByDefaultRoundedIcon 
                          sx={{color:"#d30019"}}
                          fontSize="large" />
                          <div className={styles.details}>
                            <span
                              className={styles.name}
                            >{`${file?.name} • Excede el tamaño permitido - Size: ${bytesToMegabytes(file?.size)?.toFixed(2)} MB`}</span> 
                          </div>
                        </div>
                        <Button variant="outlined" sx={{borderRadius: '10px'}}
                        onClick={() => removeFile(index)} >
                          Eliminar
                        </Button>
                      </li>}
                      </>
                    )
                  })}
                  {currentUser?.institution?.length > 1 && (
                    <div className={styles.institution}>
                      <MySelect label="Institución" name="institution">
                        {currentUser?.institution?.map((i) => (
                          <MenuItem value={i.id}>{i.name}</MenuItem>
                        ))}
                      </MySelect>
                    </div>
                  )}
                  
                  {isSubmitEnabled && 
                  <MySubmitButton name="Cargar" type="submit" />}
                </Box>
                )}
            </Box>
          </div>
        </Form>
      </Formik>
      </div>
    </Modal>
  );
}

export default LoadPictures;
