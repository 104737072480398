import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import {
  MySubmitButton,
  MyTextarea,
  MyCheckbox,
  MyDateTextInput
} from "../../FormFields";
import Modal from "../../Modal";
import { useClassJournal } from "../../../context/ClassJournalContext";
import styles from "./LessonHeader.module.css";
import LoadingComponent from "../../LoadingComponent";
import { useLoading } from "../../../context/LoadingContext";
function LessonHeader({
  activeLesson,
  sections,
  activeSection,
  getCourse,
  setActiveSection,
  currentUser
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [formDataSections, setFormDataSections] = useState([]);
  const { fillClassJournal, fillMultipleClassJournal } = useClassJournal();
  const { showLoading, hideLoading, isLoading } = useLoading();
  const navigate = useNavigate();
  const { courseId } = useParams();
  const handleCompleteLesson = (section) => {
    setActiveSection(section);
    const completed = sections.some(
      (sec) =>
        sec.name === section.name &&
        sec.class_journal.some((entry) => {
          return entry.lesson_id === activeLesson?.id && entry.completed;
        })
    );
    if (!completed) {
      setModalOpen(true);
    } else {
      navigate(`/courses/${courseId}/sections`);
    }
  };

  const sectionClasses = (section) => {
    const completed = sections.some(
      (sec) =>
        sec.name === section.name &&
        sec.class_journal.some((entry) => {
          return entry.lesson_id === activeLesson?.id && entry.completed;
        })
    );

    return completed
      ? `${styles.section} ${styles.completed}`
      : `${styles.section}`;
  };

  let formData = [];

  sections?.forEach((section) => {
    let data = {
      lesson: activeLesson?.id,
      section: section.id,
      date: "",
      completed: false,
      notes: "",
    };
    formData.push(data);
  });

  useEffect(() => {
    if (formDataSections.length === 0) {
      setFormDataSections(formData);
    }
    
  }, [activeLesson]);

  useEffect(() => {
  }, [formDataSections]);
  

  const updateCompletedStatus = (sectionId, field, value) => {
    setFormDataSections((prevState) => {
      const updatedData = prevState.map((item) =>
        item.section === sectionId
          ? { ...item, [field]: value, lesson: activeLesson?.id }
          : item
      );
      return [...updatedData]; 
    });
  };
  
  const handleFillMultiple = () => {
    setTimeout(() => {
      try {
        const filteredData = formDataSections.filter(
          (item) => item.date || item.notes || item.completed
        );
        if (filteredData.length === 0) {
          alert("No hay datos para enviar."); 
          return;
        }
          if (
          filteredData.some(item => item.notes || item.completed) &&
          filteredData.some(item => !item.date) 
        ) {
          alert("Debe ingresar una fecha.");
          return;
        }
        showLoading();
        fillMultipleClassJournal({ data: [...filteredData] });
        hideLoading();
        setOpenModal(false);
        getCourse();
        setFormDataSections((prevState) => prevState.map(item => ({
          ...item,
          date: '',
          notes: '',
          completed: false
        })));
      } catch (error) {
        hideLoading();
        alert("Ocurrió un error al enviar los datos. Intenta de nuevo.");
        console.error("Error en handleFillMultiple:", error);
      }
    }, 300);
  };
  

  
  const handleFillClassJournal = (values) => {
    showLoading();
    const fillData = {
      ...values,
      lesson: activeLesson.id,
      section: activeSection.id,
    };
    fillClassJournal({ data: fillData });
    setModalOpen(false);
    getCourse();
    hideLoading();
  };

  const varl = [];

  sections?.some((sec) =>
    sec?.class_journal?.some((entry) => {
      if (entry.lesson_id === activeLesson?.id) {
        varl.push(entry?.completed);
      }
    })
  );
  let hasDay;

  const handleCheckHasDates = () => {
    if (varl?.length < sections?.length) {
      hasDay = false;
    } else {
      if (varl?.includes(false)) {
        hasDay = false;
      } else {
        hasDay = true;
      }
    }
  };

  handleCheckHasDates();

  return (
    <>
      <div className={styles.goal}>
        {/* Excepcion para coordinador externo (2499) prueba de chile  */}
        { 
        (activeLesson?.script ||
          activeLesson?.slides) && (activeLesson?.script !== 'nan' ||
            activeLesson?.slides !== 'nan') && (
            <div className={styles["lesson-header"]}>
              <div>Material de apoyo</div>
              <div className={styles.materials}>
                {(activeLesson?.script && activeLesson?.script !== 'nan') &&
                <div className={styles.material}>
                  <a rel="noreferrer"
                    target="_blank"
                    href={activeLesson?.script}
                    className={styles.content_link}
                  >
                    <i
                      className="fal fa-file-alt"
                      style={{ fontSize: "17px" }}
                    ></i>
                    <p style={{ fontSize: "15px" }}>{`Script`}</p>
                  </a>
                </div>}
                {(activeLesson?.slides && activeLesson?.slides !== 'nan') &&
                <div className={styles.material}>
                  <a rel="noreferrer"
                    target="_blank"
                    href={activeLesson?.slides}
                    className={styles.content_link}
                  >
                    <i
                      className="fal fa-presentation"
                      style={{ fontSize: "17px" }}
                    ></i>
                    <p style={{ fontSize: "15px" }}>{`Presentación`}</p>
                  </a>
                </div>}
              </div>
            </div>
        )}

        <div className={styles["sections-header"]}>
          <div
            className={styles.sectionDescrip}
          >
            Secciones{" "}
            <p >
              Click en la seccion para marcar completada
            </p>
          </div>

          <div className={styles.sections}>
            {modalOpen && (
              <Modal
                title={"Crear entrada"}
                modalOpen={setModalOpen}
                acceptModal={() => handleCompleteLesson()}
              >
                <div className={styles.dataContainer}>
                <Formik
                  initialValues={{
                    lesson: "",
                    date: null,
                    completed: false,
                    notes: "",
                  }}
                  validationSchema={Yup.object({
                    lesson: Yup.string().min(
                      1,
                      "El campo no puede estar vacío."
                    ),
                    date: Yup.date().required("Campo requerido"),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      handleFillClassJournal(values);
                      setSubmitting(false);
                    }, 400);
                  }}
                >
                  <Form className={styles["form__fields"]}>
                    <MyDateTextInput
                      label="Fecha"
                      name="date"
                      placeholder="Título"
                      icon="fa fa-user"
                    />
                    <MyTextarea label="Notas" name="notes" />
                    <MyCheckbox name="completed">Clase completada</MyCheckbox>
                    <MySubmitButton name="Aceptar" type="submit" />
                  </Form>
                </Formik>
                </div>
              </Modal>
            )}
            {sections?.map((section) => (
              <div
                className={sectionClasses(section)}
                key={section.id}
                onClick={() => handleCompleteLesson(section)}
              >
                {section.name}
              </div>
            ))}
            {
            (formDataSections?.length > 0 && !hasDay && sections.length > 1) && (
              <div
                className={styles.section}
                style={{ fontSize: "14px" }}
                onClick={() => setOpenModal(true)}
              >
                <i className="fal fa-check"></i>
              </div>
            )}
          </div>
        </div>
      </div>
      {openModal && (
        <Modal title={"Clases vistas"} modalOpen={setOpenModal}>
          <Formik
            initialValues={formData}
            validationSchema={Yup.object({})}
            onSubmit={(values, { setSubmitting }) => {              
              setTimeout(() => {
                handleFillMultiple();
                setSubmitting(false);
              }, 400);
            }}
          >
            <Form className={styles["form__fields"]}>
              <div className={styles.journal_container}>
                {sections.map((section) => {
                  const dataItem = formDataSections.find(
                    (item) => item?.section === section?.id
                  );
                  const filteredDays = section.class_journal.filter(
                    (item) => item.lesson_id === activeLesson.id
                  );
                  let filled = filteredDays.some((item) => item.completed);
                  return (
                    <>
                      {!filled && (
                        <Accordion defaultExpanded sx={{borderRadius: '10px'}}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Typography variant="h5">{`Sección ${section.name}`}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>

                            <Grid container rowSpacing={2} columnSpacing={2}>
                              <Grid item md={4} sm={12} xs={12}>
                              <label>Fecha </label>
                              <TextField 
                                variant="outlined" 
                                size="small" 
                                value={dataItem?.date || ""}
                                fullWidth
                                onChange={(e) =>
                                  updateCompletedStatus(
                                    section.id,
                                    "date",
                                    e.target.value
                                  )
                                }
                              type="date"
                              sx={{
                                marginBottom: 1,
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "10px",
                                },
                              }} />
                            </Grid>

                            <Grid item md={5} sm={12} xs={12}>
                              <label>Notas </label>
                              <TextField variant="outlined" 
                                  size="small" 
                                  fullWidth
                                  value={dataItem?.notes || ""}
                                  onChange={(e) =>
                                    updateCompletedStatus(
                                      section.id,
                                      "notes",
                                      e.target.value
                                    )
                                  }
                                  rows={4}
                                  multiline
                                  sx={{
                                    marginBottom: 1,
                                    "& .MuiOutlinedInput-root": {
                                      borderRadius: "10px",
                                    },
                                  }} />

                            </Grid>

                            <Grid item md={3} sm={12} xs={12}>
                              <label>Completado:
                                <input
                                  type="checkbox"
                                  checked={dataItem?.completed}
                                  onChange={(e) =>
                                    updateCompletedStatus(
                                      section.id,
                                      "completed",
                                      e.target.checked
                                    )
                                  }
                                />
                              </label>
                            </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                       )}
                    </>
                  );
                })}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <MySubmitButton name="Aceptar" type="submit"/>
              </div>
            </Form>
          </Formik>
        </Modal>
      )}
      {isLoading && <LoadingComponent />}
    </>
  );
}

export default LessonHeader;
