import "./App.css";
import { useContext, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { ThemeProvider } from "@mui/material/styles"
import mainTheme from "./styles/theme/mainTheme";
import {
  CurrentUserContext,
  useCurrentUser,
} from "./context/CurrentUserProvider";
import Navbar from "./components/Navbars/Navbar";
import HomePage from "./pages/homePage";
import LoginPage from "./pages/loginPage";
import RegisterPage from "./pages/registerPage";
import ChatPage from "./pages/chatPage";
import ProfilePage from "./pages/profilePage";
import InstitutionsPage from "./pages/institutionsPage";
import Sidebar from "./components/Navbars/Sidebar";
import SettingsPage from "./pages/settingsPage";
import UsersPage from "./pages/usersPage";
import RegistroMasivoUsers from "./components/Users/RegistroMasivoUsers";
import TeachersPage from "./pages/teachersPage";
import UpdateProfileForm from "./components/Users/UpdateProfileForm";
import UserInfo from "./components/Users/UserInfo";
import UsersList from "./components/Users/UsersList";
import CoursesPage from "./pages/coursesPage";
import CoursePage from "./pages/CoursePage";
import CourseList from "./components/Courses/CourseList";
import InstitutionsList from "./components/Institutions/InstitutionsList";
import CreateInstitutionForm from "./components/Institutions/CreateInstitutionForm";
import InstitutionView from "./components/Institutions/InstitutionView";
import RegistroMasivoInstitutions from "./components/Institutions/RegistroMasivoInstitutions";
import CoursesAdmin from "./pages/coursesAdmin";
import CoursesList from "./components/CoursesAdmin/CoursesList";
import CreateCourse from "./components/CoursesAdmin/CreateCourse";
import CreateMasiveCourses from "./components/CoursesAdmin/CreateMasiveCourses";
import ProgramsPage from "./pages/programsPage";
import ProgramsList from "./components/Programs/ProgramsList";
import CreateProgram from "./components/Programs/CreateProgram";
import AnnouncementsPage from "./pages/announcementsPage";
import CreateAnnouncement from "./components/Announcements/CreateAnnouncement";
import AnnouncementsList from "./components/Announcements/AnnouncementsList";
import CourseLessons from "./components/Courses/CourseLessons";
import CourseForum from "./components/Courses/Forum/CourseForum";
import CourseSections from "./components/Courses/CourseSections";
import SurveysPage from "./pages/surveysPage";
import CreateTemplate from "./components/Surveys/SurveyAdmin/CreateTemplate";
import SurveysList from "./components/Surveys/SurveysList";
import SurveyAdminView from "./components/Surveys/SurveyAdmin/SurveyAdminView";
import CreateQuestion from "./components/Surveys/SurveyAdmin/CreateQuestion";
import SurveyView from "./components/Surveys/SurveyView";
import CalendarPage from "./pages/calendarPage";
import SendSurvey from "./components/Surveys/SurveyAdmin/SendSurvey";
import SurveyAnswers from "./components/Surveys/SurveyAdmin/SurveyAnswers";
import PreviusViewSurvey from "./components/Surveys/SurveyAdmin/PreviusViewSurvey";
import LessonsList from "./components/Programs/LessonsList";
import ProgramView from "./components/Programs/ProgramView";
import QuestionsList from "./components/Surveys/SurveyAdmin/QuestionsList";
import LicensesList from "./components/Programs/LicensesList";
import UploadPlanification from "./components/Programs/UploadPlanification";
import SeguimientoPage from "./pages/seguimientoPage";
import TeachersList from "./components/Seguimiento/TeachersList";
import PlanillasPage from "./pages/planillasPage";
import Planillas from "./components/Seguimiento/Planillas/FillPlanillas/Planillas";
import CreatePlanilla from "./components/Seguimiento/Planillas/CreatePlanilla";
import Questions from "./components/Seguimiento/Planillas/Questions";
import CapacitacionesPageAdmin from "./pages/capacitacionesPageAdmin";
import CrearCapacitacion from "./components/Seguimiento/CapacitacionesAdmin/CrearCapacitacion";
import PlanillasList from "./components/Seguimiento/Planillas/PlanillasList";
import SchedulesPage from "./pages/schedulesPage";
import CapacitacionView from "./components/Seguimiento/CapacitacionesAdmin/CapacitacionView";
import PlanillaView from "./components/Seguimiento/Planillas/PlanillaView/PlanillaView";
import ResetPasswordPage from "./pages/resetPasswordPage";
import TestUpload from "./utils/testUpload";
import InstitutionTables from "./components/Institutions/InstitutionTables";
import ReferencePage from "./pages/ReferencePage";
import ReferenceDetail from "./pages/Reference/ReferenceDetail";
import CapacitacionesPage from "./pages/capacitacionesPage";
import CapacitacionesListTeachers from "./components/Seguimiento/CapacitacionesTeacher/CapacitacionesListTeachers";
import CapacitacionTeacher from "./components/Seguimiento/CapacitacionesTeacher/CapacitacionTeacher";
import QuestionsListCap from "./components/Seguimiento/CapacitacionesAdmin/QuestionsListCap";
import CreateQuiz from "./components/Seguimiento/CapacitacionesAdmin/CreateQuiz";
import QuicesList from "./components/Seguimiento/CapacitacionesAdmin/QuicesList";
import CreateQuestionCap from "./components/Seguimiento/CapacitacionesAdmin/CreateQuestionCap";
import CreateSection from "./components/Schedules/CreateSection";
import AllAnnouncementComponent from "./components/Announcements/AllAnnouncementComponent";
import { LoadingProvider } from "./context/LoadingContext";
import RegistroFotografico from "./pages/registroFotografico";
import ReportesPage from "./pages/reportesPage";
import CreateTemplatePlanilla from "./components/Seguimiento/Planillas/CreateTemplatePlanilla";
import ReportList from "./components/Seguimiento/Reportes/ReportList";
import GenerarReporte from "./components/Seguimiento/Reportes/GenerarReporte";
import CapacitacionAnualView from "./components/CapacitacionesAnuales/Students/CapacitacionAnualView";
import CapacitacionesMain from "./components/Seguimiento/CapacitacionesAdmin/CapacitacionesMain";
import AdminCapacitacionView from "./components/CapacitacionesAnuales/Admin/AdminCapacitacionView";
import TrainingOverview from "./components/CapacitacionesAnuales/Admin/TrainingOverview";
import EventsPage from "./pages/eventPage";
import EventsMain from "./components/Events/EventsMain";
import EventAdmin from "./components/Events/Admin/EventAdmin";
import RequestAdmin from "./components/Events/Admin/RequestAdmin";
import IncidenceAdmin from "./components/Events/Admin/IncidenceAdmin";
import CreateActivity from "./components/Events/CreateActivity";
import AllViewList from "./components/Events/AllEventList";
import AllViewListExternal from "./components/Events/ExternalAllEvents.js/AllEventExternal";
import EventsPageExt from "./pages/EventPageExt";
import LoadingComponent from "./components/LoadingComponent";
import DevelopmentPage from "./pages/DevelopmentPage";
import InformationAdmin from "./components/Events/Admin/InformationAdmin";

function App() {

  const { currentUser, validToken, setValidToken } =
    useCurrentUser(CurrentUserContext);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const token = currentUser?.tokens?.access;
    const isDirectAccess = window.location.pathname !== "/";
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp * 1000;
        if (expirationTime < Date.now()) {
          localStorage.clear();
        } else {
          setValidToken(true);
          !isDirectAccess && navigate("/");
        }
      } catch (error) {
        console.error("Token decoding failed:", error);
        localStorage.clear();
        setValidToken(false);
        navigate("/login");
      }
    }else{
      localStorage.clear();
      setValidToken(false);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (currentUser === null) {
      localStorage.clear();
      navigate("/")
    }
  }, [currentUser]);

  useEffect(() => {}, [validToken]);

  if (isLoading) {
    return <LoadingComponent />;
  } else {
    return (
      <div className="App">
        <ThemeProvider theme={mainTheme}>
        <LoadingProvider>
  
        {validToken ? (
          <>
            <Sidebar />
            <div className="Content">
              <Navbar />
              <div className="cont">
              <Routes>
              
                <Route
                  path="profile"
                  element={
                    <RequireAuth redirectTo="/login">
                      <ProfilePage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="profile/:userId"
                  element={
                    <RequireAuth redirectTo="/login">
                      <ProfilePage />
                    </RequireAuth>
                  }
                >
                  <Route
                    path="view"
                    element={
                      <RequireAuth redirectTo="/login">
                        <UserInfo />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="edit"
                    element={
                      <RequireAuth redirectTo="/login">
                        <UpdateProfileForm />
                      </RequireAuth>
                    }
                  />
                </Route>
                <Route
                  path="/institutions"
                  element={
                    <RequireAuth redirectTo="/login">
                      <HasPermission>
                        <InstitutionsPage />
                      </HasPermission>
                    </RequireAuth>
                  }
                >
                  <Route element={<InstitutionsList />} path="list" />
                  <Route element={<CreateInstitutionForm />} path="create" />
                  <Route
                    element={<RegistroMasivoInstitutions />}
                    path="upload"
                  />
                  <Route path=":institutionId" element={<InstitutionView />}>
                    <Route element={<InstitutionTables />} path="levels" />
                  </Route>
                </Route>
                <Route
                  path="chats"
                  element={
                    <RequireAuth redirectTo="/login">
                      <ChatPage />
                    </RequireAuth>
                  }
                />
                <Route element={<LoginPage />} path="/login" />
                <Route element={<HomePage />} path="/" />
                <Route element={<SchedulesPage />} path="/schedules">
                  <Route element={<CreateSection />} path=":levelId" />
                </Route>
                <Route
                  element={
                    <HasPermission>
                      <SettingsPage />
                    </HasPermission>
                  }
                  path="/ajustes"
                />
                <Route
                  element={
                    <HasPermission>
                      <UsersPage />
                    </HasPermission>
                  }
                  path="/users"
                >
                  <Route element={<UsersList />} path="list" />
                  <Route element={<RegisterPage />} path="create" />
                  <Route element={<RegistroMasivoUsers />} path="upload" />
                </Route>
                <Route element={<CoursesPage />} path="/courses">
                  <Route element={<CourseList />} path="list" />
                  <Route element={<CoursePage />} path=":courseId">
                    <Route element={<CourseLessons />} path="classes" />
                    <Route element={<CourseForum />} path="forum"></Route>
                    <Route element={<CourseSections />} path="sections" />
                  </Route>
                </Route>
                <Route element={<SurveysPage />} path="/encuestas">
                  <Route element={<SurveysList />} path="list" />
                  <Route element={<CreateQuestion />} path="create-question" />
                  <Route element={<QuestionsList />} path="questions" />
                  <Route element={<CreateTemplate />} path="create-template" />
                  <Route element={<SurveyAdminView />} path="admin/:templateId">
                    <Route element={<CreateTemplate />} path="editar" />
                    <Route
                      element={<PreviusViewSurvey />}
                      path="vista-previa"
                    />
                    <Route element={<SendSurvey />} path="enviar" />
                    <Route element={<SurveyAnswers />} path="answers" />
                  </Route>
                  <Route element={<SurveyView />} path=":templateId" />
                </Route>
                <Route
                  element={
                    <HasPermission>
                      <TeachersPage />
                    </HasPermission>
                  }
                  path="/teachers"
                />
                <Route
                  element={
                    <HasITPermission>
                      <CoursesAdmin />
                    </HasITPermission>
                  }
                  path="/courses-admin"
                >
                  <Route element={<CoursesList />} path="list" />
                  <Route element={<CreateCourse />} path="create" />
                  <Route element={<CreateCourse />} path=":courseId" />
                  <Route element={<CreateMasiveCourses />} path="upload" />
                </Route>
                <Route
                  element={
                    <HasPermission>
                      <ProgramsPage />
                    </HasPermission>
                  }
                  path="/programs"
                >
                  <Route element={<ProgramsList />} path="list" />
                  <Route element={<CreateProgram />} path="create" />
                  <Route element={<LicensesList />} path="licenses" />
                  <Route element={<LessonsList />} path="lessons" />
                  <Route element={<ProgramView />} path=":programId" />
                  <Route
                    element={<UploadPlanification />}
                    path=":programId/upload"
                  />
                  <Route element={<CreateProgram />} path=":programId/edit" />
                  
                </Route>
                {/* <Route element={<ConfidentialityAgreementModal />} path="/acuerdo-confidencialidad" /> */}
                <Route element={<TestUpload />} path="/createv2" />
                <Route element={<AnnouncementsPage />} path="/alertas">
                  <Route element={<AnnouncementsList />} path="list" />
                  <Route element={<CreateAnnouncement />} path="create" />
                  <Route element={<AllAnnouncementComponent />} path="all" />
                </Route>
                <Route element={<CalendarPage />} path="/calendario" />
                <Route element={<ReferencePage />} path="/reference">
                  <Route element={<ReferenceDetail />} path=":referenceId" />
                </Route>
                <Route
                  element={<SeguimientoPage />}
                  path="/seguimiento"
                >
                </Route>
                <Route
                  element={<RegistroFotografico />}
                  path="/seguimiento/registro-fotografico"
                />
                <Route
                  element={<TeachersList />}
                  path="/seguimiento/teachers"
                />
                <Route
                  path="training-overview"
                  element={
                    <RequireAuth redirectTo="/login">
                      <TrainingOverview />
                    </RequireAuth>
                  }
                />
                <Route
                  element={<EventsPage />}
                  path="/seguimiento/events"
                >
                  <Route element={<EventsMain />} path="list" />
                  <Route element={<EventAdmin />} path="event/:eventId" />
                  <Route element={<RequestAdmin />} path="request/:eventId" />
                  <Route element={<IncidenceAdmin />} path="incidence/:eventId" />
                  <Route element={<InformationAdmin />} path="information/:eventId" />
                  <Route element={<CreateActivity />} path="create" />
                  <Route element={<AllViewList />} path="list-all" />
                  <>
                  {/*<Route element={<CapacitacionesMain />} path="list" />
                  <Route element={<AdminCapacitacionView />} path="anuales/:trainingId" />
                  <Route
                    element={<CrearCapacitacion />}
                    path="agregar-capacitacion"
                  >
                    <Route element={<CrearCapacitacion />} path=":trainingId" />*/} 
                  </>
                </Route>
                
                <Route element={<ReportesPage />} path="/seguimiento/reports" >
                  <Route element={<ReportList />} path="list" />
                  <Route element={<GenerarReporte />} path="crear" />
                </Route>
                
                <Route element={<PlanillasPage />} path="/seguimiento/planilla">
                  <Route element={<Planillas />} path="llenar" />
                  <Route element={<CreatePlanilla />} path="crear-pregunta" />
                  <Route element={<CreateTemplatePlanilla />} path="crear-template" />
                  <Route element={<Questions />} path="preguntas" />
                  <Route element={<PlanillasList />} path="list" />
                  <Route element={<PlanillaView />} path=":planillaId" />
                </Route>
                <Route
                  element={<CapacitacionesPageAdmin />}
                  path="/seguimiento/capacitaciones"
                >
                  <Route element={<CapacitacionesMain />} path="list" />
                  <Route element={<AdminCapacitacionView />} path="anuales/:trainingId" />
                  <Route
                    element={<CrearCapacitacion />}
                    path="agregar-capacitacion"
                  >
                    <Route element={<CrearCapacitacion />} path=":trainingId" />
                  </Route>
                  <Route
                    element={<CreateQuestionCap />}
                    path="agregar-pregunta"
                  ></Route>
                  <Route
                    element={<QuestionsListCap />}
                    path="lista-preguntas"
                  ></Route>
                  <Route
                    element={<QuicesList />}
                    path="lista-quices"
                  ></Route>
                  <Route
                    element={<CreateQuiz />}
                    path="agregar-quiz"
                  ></Route>
                  <Route
                    element={<CapacitacionView />}
                    path=":capacitacionId"
                  />
                </Route>
                <Route element={<CapacitacionesPage />} path="/capacitaciones">
                  <Route
                    element={<CapacitacionTeacher />}
                    path=":capacitacionId"
                  />
                  <Route
                    element={<CapacitacionAnualView />}
                    path="anuales/:trainingId"
                  />
                  <Route element={<CapacitacionesListTeachers />} path="list" />
                </Route>
                <Route path="/events" element={<EventsPageExt />}>
                  <Route element={<AllViewListExternal />} path="list" />
                  <Route element={<CreateActivity />} path="create" />
                  <Route element={<EventAdmin />} path="event/:eventId" />
                  <Route element={<RequestAdmin />} path="request/:eventId" />
                  <Route element={<IncidenceAdmin />} path="incidence/:eventId" />
                  <Route element={<InformationAdmin />} path="information/:eventId" />

                </Route>
                <Route path="/development" element={<DevelopmentPage />}>
                </Route>
                
                <Route
                  element={<EventsPage />}
                  path="/development/events"
                >
                  <Route element={<EventsMain />} path="list" />
                  <Route element={<EventAdmin />} path="event/:eventId" />
                  <Route element={<RequestAdmin />} path="request/:eventId" />
                  <Route element={<IncidenceAdmin />} path="incidence/:eventId" />
                  <Route element={<InformationAdmin />} path="information/:eventId" />
                  <Route element={<CreateActivity />} path="create" />
                  <Route element={<AllViewList />} path="list-all" />
                </Route>
                <Route
                  element={<EventsPage />}
                  path="/purchases/events"
                >
                  <Route element={<EventsMain />} path="list" />
                  <Route element={<EventAdmin />} path="event/:eventId" />
                  <Route element={<RequestAdmin />} path="request/:eventId" />
                  <Route element={<IncidenceAdmin />} path="incidence/:eventId" />
                  <Route element={<InformationAdmin />} path="information/:eventId" />
                  <Route element={<CreateActivity />} path="create" />
                  <Route element={<AllViewList />} path="list-all" />
                </Route>

              </Routes>
              </div>
            </div>
          </>
        ) : (
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route element={<LoginPage />} path="/login" />
            <Route element={<ResetPasswordPage />} path="/reset" />
          </Routes>
        )}
        </LoadingProvider>
        </ThemeProvider>
      </div>
    );
  }
}

function RequireAuth({ children, redirectTo }) {
  let { currentUser } = useContext(CurrentUserContext);
  let isAuthenticated = Boolean(currentUser);

  return !isAuthenticated ? <Navigate to={redirectTo} /> : children;
}

function HasPermission({ children }) {
  let { currentUser } = useContext(CurrentUserContext);
  const userPermission =
    currentUser?.role === "IT" ||
    currentUser?.role === "monitoring_coordinator" ||
    currentUser?.role === "monitoring_teacher" ||
    currentUser?.role === "monitoring_teacher_3";

  // return !userPermission ? <Navigate to="/" /> : children;
  return false ? <Navigate to="/" /> : children;
}

function HasITPermission({ children }) {
  let { currentUser } = useContext(CurrentUserContext);
  const userPermission = currentUser?.role === "IT";
  // return !userPermission ? <Navigate to="/" /> : children;
  return false ? <Navigate to="/" /> : children;
}

export default App;
