import React, {useState, useRef, useContext} from 'react'
import { Formik, Form } from "formik";
import { json, useParams } from "react-router-dom";
import * as Yup from "yup";
import { MenuItem } from '@mui/material';
import { MySubmitButton, MySelect, MyTextInput } from "../FormFields";
import { ProgramsContext } from '../../context/ProgramsContext';
import styles from './UploadPlanification.module.css'
import { useLoading } from "../../context/LoadingContext";
import LoadingComponent from "../LoadingComponent";
import ReadPlanification from './Planifications/ReadPlanification';

function UploadPlanification() {
    const {uploadPlanifiaction, uploadPlanifiactionNotFake} = useContext(ProgramsContext)
    const [showFileUploader, setShowFileUploader] = useState(true);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState({});
  const fileInputRef = useRef(null);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const [not_fake, setNot_fake] = useState(false);
  const [planification, setPlanification] = useState([]);
  const [stage, setStage] = useState("");
  const [version, setVersion] = useState("");

  const { programId } = useParams();

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleOnChange = (e) => {
    setShowFileUploader(false);
    setFileName(e.target.files[0].name);
    setFile(e.target.files[0]);
    setFileUploaded(true);
  };

  const createHandler = async (data) => {
    showLoading()
    let file_result = document.getElementById("file_result");
    file_result.innerHTML = "";
    
    if(stage == "" || version == ""){
      file_result.innerHTML = "Debe seleccionar una etapa y una versión";
    }else if (not_fake) {
    
        const response = await uploadPlanifiactionNotFake({data: {lessons: planification, program: programId}});
        
        if (response?.error){
          file_result.innerHTML = JSON.stringify(response.data?.error);
        }else{
          file_result.innerHTML = JSON.stringify(response.data);
          setNot_fake(false)
          setPlanification([])
          setStage('');
          setVersion('');
        }

    }else {

      try {

        let pl = await ReadPlanification(data);
      
        if(pl.error.length == 0){

          file_result.innerHTML = `Planificación lista para ser subida. ${pl.lessons.length} clases.`;

          setPlanification(pl.lessons);
          setNot_fake(true);
          
        }else{
          file_result.innerHTML = JSON.stringify(pl.error);
        }

      } catch (error) {
        file_result.innerHTML = JSON.stringify(error);
        console.log(error);
      }

    }

    
    
    setShowFileUploader(true)
    setFileUploaded(false)
    hideLoading()
  };

  return (
    <div className={styles.container}>
        <Formik
        initialValues={{
          stage: "",
          version: "1",
        }}
        validationSchema={Yup.object({
          stage: Yup.string().min(1, "El campo no puede estar vacío."),
          version: Yup.string().min(1, "El campo no puede estar vacío."),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
            const formData = new FormData();
            formData.append("stage", stage);
            formData.append("version", version);
            formData.append("program", programId);
            formData.append("file", file);
          setTimeout(() => {
            createHandler(formData);
            setSubmitting(false);
          }, 400);
          resetForm();
        }}
      >
        <Form className={styles["form"]}>
          <div className={styles["form__fields"]}>
            <div style={{paddingLeft: '25px'}}>
            <MySelect label="Etapa" name="stage" disabled={not_fake} onChange={(e) => setStage(e.target.value)} value={stage}>
                <MenuItem value="Preescolar">Preescolar</MenuItem>
                <MenuItem value="Primaria Menor">
                  Primaria Menor
                </MenuItem>
                <MenuItem value="Primaria Mayor">
                  Primaria Mayor
                </MenuItem>
                <MenuItem value="Bachillerato">Bachillerato</MenuItem>
            </MySelect>
            <MyTextInput label="Versión" name="version" disabled={not_fake} value={version} onChange={(e) => setVersion(e.target.value)}/>
            </div>
           
            <div className={styles.wrapper}>
                {showFileUploader && !not_fake && (
                  <form
                    action="#"
                    onClick={handleUploadClick}
                    encType="multipart/form-data"
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={handleOnChange}
                      disabled={not_fake}
                    ></input>
                    <i className="fas fa-cloud-upload-alt"></i>
                    <p>{`Click para subir archivo`}</p>
                  </form>
                )}
                {(fileUploaded || not_fake) && (
                  <section className={styles.uploadedarea}>
                    <li className={styles.row}>
                      <div className={styles.content}>
                        <i className="fas fa-file-alt"></i>
                        <div className={styles.details}>
                          <span
                            className={styles.name}
                          >{`${fileName} • Uploaded`}</span>
                        </div>
                      </div>
                      <i className="fas fa-check"></i>
                    </li>
                  </section>
                )}
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
            
            <MySubmitButton name={!not_fake ? 'Probar Planificación' : 'Guardar Planificación'} type="submit" style={!not_fake ? {} : {backgroundColor: '#0f0'}}/>

            </div>
            
            <div style={{display: 'flex', 
                         justifyContent: 'left', 
                         border: '1px solid #999', 
                         overflow: 'scroll-v', 
                         borderRadius: '5px',
                         minHeight: '100px',
                         maxHeight: '500px',
                         margin: '25px',
                         padding: '10px'}} id="file_result"></div>
            
          </div>
        </Form>
      </Formik>
      {isLoading && <LoadingComponent />}
    </div>
  )
}

export default UploadPlanification
